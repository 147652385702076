import React from 'react'
//import starIcon from '../../assets/images/star-icon.png'
//import client1 from '../../assets/images/testimonials/client1.jpg'
//import client2 from '../../assets/images/testimonials/client2.jpg'
//import client3 from '../../assets/images/testimonials/client3.jpg'
//import shape from '../../assets/images/shape/shape1.svg'
import SidebarMenu from './Sidebar';
import Bnj from '../../assets/images/business/Bnj.jpg'
import Bnb from '../../assets/images/business/Bnb.jpg'
import Mrm from '../../assets/images/business/Mrm.jpg'
import Mmm from '../../assets/images/business/Mmm.jpg'
import Ben from '../../assets/images/business/Ben.jpg'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Image } from 'semantic-ui-react'
import mrm from '../../assets/images/mrmicon.png'
import bnbdetail from '../../assets/images/bnbicon.png'
//import fadetail from '../../assets/images/faicon.png'
import mmmdetail from '../../assets/images/mmmicon.png'
import mendetail from '../../assets/images/menicon.png'
import { Helmet } from 'react-helmet'



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};




const ImgSlide = [
    `${Bnj}`, `${Bnb}`, `${Mrm}`, `${Mmm}`, `${Ben}`
]

const Trading = () => {
    return (
        <div className="testimonials-area pt-100 pb-70 bg-fafafb">
            <Helmet>
                <title>Bumi Nusantara Group - Trading and Mining</title>
                <meta charSet='utf-8' />
                <meta name='title' content='Bumi Nusantara Group' />
                <meta name="description" content="Bumi Nusantara Group" />
                <meta name='keywords' content='Bumi Nusantara Group, Bumi Nusantara Jaya, Bumi Nusantara Bara, Maharaja Mineral, Samudra Ulam Nusantara, Ulam Laut Nusantara, Energi, Batu Bara, Biji Besi, Nikel, Ulam, Iron Ore, BNJ, BNG, BNB, MRM, FA, Mining, Trading, Farindo Agung, Binala Energi Nusantara, Ulam Nusantara Abadi, Ulam Laut Nusantara, Samudra Ulam Nusantara, Berkat Kelautan Nusantara, Sinar Purefoods, Maha Raja Energi, Adimitra Hidro Nusantara, Arta Samudra Petroleum, Tiara Bumi Petroleum, Selera Maharaja Nusantara, Sikunci Sigaret Srintil, Sungai Indah Investama, Sungai Indah Artha Mulia, Sungai Indah Gemilang Lokantara, Sungai Indah Kencana Utama, Bumi Nusantara Armada, Elpi Nusantara Armada, Ulam Armada Nusantara, Kapal Sakti, Fiesheries, Tuna, Ikan Tuna, Ulam Nusantara Abadi, Ulam Laut Nusantara, Samudra Ulam Nusantara, Berkat Kelautan Nusantara, Sinar Purefoods, Rokok Kapal Sakti' />
                <meta name='author' content='BNG' />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta property='og:url' content='https://buminusa.co.id' />
                <meta property='og:url' content='https://buminusagroup.co.id' />
                <meta property='og:site_name' content='Bumi Nusantara Group' />
                <meta property='article:publisher' content='Get info PT. Maharaja Nusantara Energi' />
                <meta property='og:title' content='Bumi Nusantara Group' />
                <meta property='og:description' content='Welcome Bumi Nusantara Group' />
                <meta name="og:title" property="og:title" content="Bumi Nusantara Group"></meta>
                <meta name="twitter:card" content="Bumi Nusantara Group"></meta>
                <link rel="canonical" href="https://buminusa.co.id"></link>
                <link rel='canonical' href='https://buminusagroup.co.id'></link>
                <meta property="og:image" content="https://buminusagroup.co.id/upload/bnjgroup.png" />
            </Helmet>

            {/*<div className="rcontainer">

                <div className="d-flex justify-content-center">

                    <h3>PT. Maharaja Nusantara Energi</h3>
                </div>

                <div className="d-flex justify-content-center">

                    <p style={{ textAlign: 'center' }}>PT. Maharaja Nusantara Energi is a subholding for our mining and trading companies. We possess a diverse portfolio of mining assets in prime resource-rich regions and dedicated trading divisions with experts and professionals on board.</p>
                </div>
                <div>&nbsp;</div><div>&nbsp;</div>
                <div className='box-rcontainer'>
                    <div className='box'>
                        <img src={bnj} alt="Bumi Nusantara Jaya" />
                    </div>
                    <div className='box'>
                        <img src={bnbdetail} alt="Bumi Nusantara Bara" />
                    </div>
                    <div className='box'>
                        <img src={mrm} alt="Maharaja Mineral" />
                    </div>
                    <div className='box'>
                        <img src={mmmdetail} alt="Maharaja Manira Mineral" />
                    </div>
                   
                    <div className='box'>
                        <img src={mendetail} alt="Binala Energi Nusantara" />
                    </div>
                </div>
            </div>*/}

            <div className='rcontainer'>
                <div class="row text-center no-gutters">
                    <div class="col-lg-3 col-sm-12 left">
                        <SidebarMenu />
                    </div>
                    <div class="col-lg-9 col-sm-12 right">
                        <div className='row'>

                            <div class="col">
                                <Carousel
                                    responsive={responsive}
                                    partialVisbile={false}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    infinite={true}
                                    arrows={true}
                                    autoPlay={true}
                                    autoPlaySpeed={5000}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    itemClass='carouselItem'
                                >
                                    {ImgSlide.slice(0, 5).map(img => {
                                        return (
                                            <Image
                                                draggable={false}
                                                style={{ width: "100%", height: "100%" }}
                                                src={img} />
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <div className='cardslide'>
                                <div class="col">
                                    <div style={{ marginTop: '30px', marginBottom: '30px', marginLeft: '10px', marginRight: '10px' }}>
                                        <div className="d-flex justify-content-center">

                                            <h3>PT. Maharaja Nusantara Energi</h3>

                                        </div>

                                        <div className="d-flex justify-content-center">

                                            <p style={{ textAlign: 'center' }}>PT. Maharaja Nusantara Energi is a subholding for our mining and trading companies. We possess a diverse portfolio of mining assets in prime resource-rich regions and dedicated trading divisions with experts and professionals on board.</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trading